@font-face {
  font-family: Kanit-Light;
  src: url("Kanit-Light.4ad15587.ttf");
}

@font-face {
  font-family: Kanit-SemiBold;
  src: url("Kanit-SemiBold.03d7b0fc.ttf");
}

html {
  font-family: Kanit-Light, Kanit-SemiBold;
  font-size: 62.5%;
}

body {
  background: #fff;
  margin: 0;
  font-size: 1.4rem;
  font-weight: 400;
}

#pc-head {
  position: relative;
}

#pc-head:after {
  content: "";
  border-bottom: 1px solid #999;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scaleY(.5);
}

.box-width {
  max-width: 1200px;
  margin: 0 auto;
}

.header-width {
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}

.header-width .logo {
  margin-left: -15px;
}

.header {
  box-sizing: border-box;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 8rem;
  font-family: Kanit-SemiBold;
  font-size: 1.4rem;
  display: flex;
}

.header .left {
  flex: 1;
  align-items: center;
  display: flex;
}

.header .left .logo {
  height: 7.5rem;
  position: relative;
  bottom: -.3rem;
}

.header .left .left-text {
  color: #3671ee;
  margin-left: -12px;
  font-size: 18px;
}

.header .left .left-text > div {
  line-height: 20px;
}

.header .right {
  align-items: center;
  display: flex;
}

.header .down-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: 20px;
}

.header .down-icon:hover path {
  fill: #3671ee !important;
}

.header .connect-btn {
  box-sizing: border-box;
  cursor: pointer;
  background: #3671ee;
  border-radius: 60px;
  justify-content: center;
  align-items: center;
  width: 158px;
  height: 40px;
  display: flex;
}

.header .connect-btn .btn-label {
  color: #fff;
  margin-left: 6px;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.header .has-connect-btn {
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 60px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 158px;
  height: 40px;
  display: inline-flex;
  position: relative;
}

.header .has-connect-btn .logout-con {
  display: none;
}

.header .has-connect-btn:hover .logout-con {
  z-index: 1;
  border-top: 4px solid #0000;
  display: block;
  position: absolute;
  top: 40px;
  left: 0;
}

.header .has-connect-btn:hover .logout-con .logout {
  cursor: pointer;
  box-sizing: border-box;
  text-align: right;
  background: #fff;
  border: 1px solid #000;
  border-radius: 60px;
  align-self: stretch;
  align-items: center;
  width: 158px;
  height: 40px;
  padding: 0 16px;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.header .has-connect-btn:hover svg {
  transform: scaleY(-1);
}

.header .has-connect-btn .address {
  color: #000;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.header .has-connect-btn svg {
  width: 12px;
  height: 12px;
}

.header .faqs {
  color: #121212;
  text-align: center;
  padding: 1.5rem 2rem;
  font-family: Open Sans;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.header .faqs:hover {
  color: #3671ee;
}

.header .new-con {
  align-items: center;
  display: flex;
}

.header .new-con svg {
  margin-left: .5rem;
}

.header .button {
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  background: #3775f6;
  border: 2px solid #3775f6;
  border-radius: 2rem;
  width: 12rem;
  height: 4rem;
  margin-left: 2rem;
  font-family: Open Sans;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 3.6rem;
  display: inline-block;
}

.header .button:hover {
  color: #000;
  background: #fff;
  border: 2px solid #000;
  border-radius: 2rem;
}

.top-con {
  background: #fff url("rectangle.6cf13224.png") 100% 0 / auto 850px no-repeat;
  width: 100%;
}

.top-con > div {
  justify-content: space-between;
  align-items: center;
  padding: 8rem 0 50rem;
  display: flex;
  position: relative;
}

.top-con .top-left {
  flex: 1;
}

.top-con .title {
  color: #121212;
  padding-top: 30px;
  font-family: Open Sans;
  font-size: 75px;
  font-style: normal;
  font-weight: 700;
}

.top-con .title2 {
  color: #3671ee;
  margin-bottom: 30px;
  font-family: Open Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.top-con .padding-left {
  margin-left: 30px;
}

.top-con .top-left-title {
  color: #333;
  margin-left: 30px;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  position: relative;
}

.top-con .top-left-title:after {
  content: "";
  background-color: #333;
  border-radius: 50px;
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  top: 11px;
  left: -20px;
}

.top-con .top-left-title .tag {
  color: #fff;
  background-color: #3671ee;
  border-radius: 2px;
  height: 18px;
  margin-left: 1rem;
  padding: 0 6px;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  display: inline-block;
  position: relative;
  top: 0;
  right: 0;
}

.top-con .top-left-title .tag:before {
  content: " ";
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-right: 5px solid #3671ee;
  width: 0;
  height: 0;
  position: absolute;
  top: 4px;
  left: -4px;
}

.top-con .top-left-text {
  color: var(--light-t-1-day, #333);
  margin-left: 30px;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

.top-con .top-left-btn {
  background: var(--dark-tanglepay, #3671ee);
  color: var(--dark-t-1, #fff);
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid var(--light-t-1-day, #3671ee);
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  width: 230px;
  margin-top: 40px;
  padding: 10px;
  font-family: Open Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  box-shadow: 4px 4px 24px #0000001f;
}

.top-con .top-left-btn:hover {
  color: #333;
  border: 2px solid var(--light-t-1-day, #333);
  background: var(--dark-t-1, #fff);
  box-shadow: 4px 4px 24px #0000001f;
}

.top-con .top-left-btn:hover svg path {
  fill: #000;
}

.top-con .top-left-btn svg {
  margin-left: 13px;
}

.btn-con {
  box-sizing: border-box;
  background: #fafaff;
  border-radius: 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 210px;
  padding: 0 55px;
  display: flex;
  position: absolute;
  bottom: 11rem;
  box-shadow: 4px 4px 24px #0000001f;
}

.btn-con .show-img {
  display: none;
}

.btn-con .button {
  width: auto;
}

.btn-con .button > img {
  height: 82px;
}

.btn-con .button:hover > img {
  display: none;
}

.btn-con .button:hover .show-img {
  display: block;
}

.btn-con .img-1 > img {
  width: 245px;
}

.top-right {
  justify-content: flex-end;
  width: 61%;
  padding-left: 20px;
  display: flex;
  position: relative;
}

.top-right .app-border > img {
  border-radius: 16px;
  width: 100%;
  overflow: hidden;
  box-shadow: 4px 4px 24px #0000001f;
}

.top-right .app-box {
  width: 45%;
  height: 617px;
}

.top-right .app-box > img {
  border-radius: 16px;
  width: 100%;
  overflow: hidden;
  box-shadow: 4px 4px 24px #0000001f;
}

.top-right .app-box2 {
  border-radius: 12px;
  width: 40%;
  height: 467px;
  margin-top: 100px;
  margin-left: 35px;
}

.top-right .app-box2 > img {
  border-radius: 16px;
  width: 100%;
  overflow: hidden;
  box-shadow: 4px 4px 24px #0000001f;
}

.top-right .user-box {
  box-sizing: border-box;
  background: var(--white-100, #fff);
  border-radius: 16px;
  align-items: center;
  width: 140px;
  height: 50px;
  padding: 0 5px;
  display: flex;
  position: absolute;
  top: 40px;
  right: 0;
  box-shadow: 4px 4px 12px #0000001f;
}

.top-right .user-box div {
  width: 40px;
  height: 40px;
}

.top-right .user-box p {
  flex: 1;
  margin-left: 10px;
  overflow: hidden;
}

.milestone-box {
  background: #f2f2f6;
  padding: 80px 0;
}

.milestone-box .box-width {
  justify-content: space-between;
  display: flex;
  position: relative;
}

.milestone-box .box-width:after {
  content: "";
  border: 2px dashed #00000029;
  border-radius: 36px;
  display: block;
  position: absolute;
  inset: 0 210px;
}

.milestone-box .mil-left {
  z-index: 2;
  width: 420px;
  padding-top: 40px;
  position: relative;
}

.milestone-box .mil-left .middle-title > div {
  width: 100%;
  padding: 1.5rem 0;
}

.milestone-box .mil-left .mil-list {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 36px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 420px;
  height: 540px;
  margin: 40px auto 0;
  padding: 3.4rem 0;
  display: flex;
  box-shadow: 4px 4px 24px #0000001f;
}

.milestone-box .mil-left .mil-list > div {
  text-align: center;
  background: #3671ee;
  border-radius: 16px;
  width: 65%;
  height: 110px;
  box-shadow: 0 4px 24px #0003;
}

.milestone-box .mil-left .mil-list > div > div {
  box-sizing: border-box;
  color: #fff;
  height: 73px;
  padding-top: 20px;
  font-family: Open Sans;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
}

.milestone-box .mil-left .mil-list > div > p {
  color: #fff;
  -webkit-font-feature-settings: "clig" off, "liga" off;
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 0;
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}

.milestone-box .mil-right {
  z-index: 2;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  width: 400px;
  display: flex;
  position: relative;
}

.milestone-box .mil-right img {
  width: 750px;
  position: relative;
  right: -83px;
}

.content-con {
  background: #fff;
  padding: 5rem 0;
}

.content-con .box-width {
  padding: 6rem 0;
  position: relative;
}

.content-con .box-width:after {
  content: "";
  border: 2px dashed #00000029;
  border-radius: 36px;
  display: block;
  position: absolute;
  inset: 9rem 180px 0;
}

.content-con .box-width:before {
  content: "";
  border-left: 2px dashed #00000029;
  display: block;
  position: absolute;
  top: 9rem;
  bottom: 0;
  left: 50%;
}

.middle-title {
  z-index: 2;
  justify-content: center;
  display: flex;
  position: relative;
}

.middle-title > div {
  background: var(--dark-t-1, #fff);
  border: 1px solid #00000021;
  border-radius: 20px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 1.5rem 5rem;
  display: flex;
  box-shadow: 4px 4px 36px #0000001f;
}

.middle-title svg {
  width: 22px;
  height: 32px;
}

.middle-title span {
  color: #000;
  text-align: center;
  margin-left: -7px;
  font-family: Open Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.middle-con {
  z-index: 2;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 5.5rem;
  display: flex;
  position: relative;
}

.middle-con .item {
  box-sizing: border-box;
  background: var(--dark-t-1, #fff);
  border: 1px solid #00000029;
  border-radius: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30.2%;
  height: 48rem;
  padding: 0 3rem;
  display: flex;
  box-shadow: 4px 4px 24px #0000001f;
}

.middle-con .item img {
  height: 15rem;
}

.middle-con .item .title {
  text-align: center;
  margin: 2rem 0;
  font-size: 2rem;
  font-weight: 700;
  line-height: 3rem;
}

.middle-con .item .label {
  text-align: center;
  font-size: 1.6rem;
}

.investors {
  background: #f2f2f6;
  width: 100%;
  padding: 10rem 0;
}

.investors .box-width {
  position: relative;
}

.investors .box-width:after {
  content: "";
  border: 2px dashed #00000029;
  border-radius: 36px;
  display: block;
  position: absolute;
  inset: 3.2rem 0 -20px;
}

.investors .middle-title svg {
  width: 29px;
  height: 39px;
}

.investors .investors-img-con {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5rem;
  display: flex;
}

.investors .investors-img-con .investors-img-item {
  background: #fff;
  border: 1px solid #00000029;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 20.5%;
  margin: 0 2rem 3rem;
  display: flex;
  box-shadow: 4px 4px 24px #0000001f;
}

.investors .investors-img-con .investors-img-item img {
  width: 80%;
}

.bottom-con {
  box-sizing: border-box;
  background: #fff;
  width: 100%;
  padding: 8rem 0;
  position: relative;
}

.bottom-con:after {
  content: "";
  border-bottom: 1px solid #999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: scaleY(.5);
}

.bottom-con .bottom-width {
  width: 890px;
  margin: 0 auto;
}

.bottom-con .logo-con {
  align-items: center;
  display: flex;
}

.bottom-con .logo-con .logo {
  height: 7rem;
  margin-left: -15px;
  position: relative;
  bottom: 0;
}

.bottom-con .logo-con .left-text {
  color: #333;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  top: -2px;
  left: -10px;
}

.bottom-con .logo-con .left-text > div {
  text-align: left;
  font-family: Kanit-SemiBold;
  line-height: 20px;
}

.bottom-con .bottom-box {
  display: flex;
}

.bottom-con .foot-bottom-text {
  flex: 1;
}

.bottom-con .foot-text {
  color: #333;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.bottom-con .link-con {
  align-items: center;
  width: 100%;
  padding: 2rem 0;
  display: flex;
}

.bottom-con .link-con .link-item {
  background-color: #000;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1.3rem;
  text-decoration: none;
  display: flex;
}

.bottom-con .link-con .link-item svg {
  width: 1.6rem;
  height: 1.6rem;
}

.bottom-con .link-con .foot-info {
  color: var(--light-t-1-day, #333);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.bottom-con .service-con {
  width: 31%;
  position: relative;
}

.bottom-con .service-con a {
  color: #888d95;
  margin-bottom: 1rem;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

.bottom-con .service-con .lang-box {
  color: #888d95;
  align-items: center;
  font-family: Open Sans;
  font-size: 12px;
  display: flex;
  position: absolute;
  top: 0;
  right: 50px;
}

.bottom-con .service-con .lang-box svg {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 7px;
}

.button {
  cursor: pointer;
}

.button:active {
  opacity: .7;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #0000001a;
  border-radius: 4px;
}

.hidden {
  display: none !important;
}

.mask-con {
  z-index: 1000;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 8rem 0 0;
}

.mask-panel {
  background-color: #121212;
  height: 100vh;
}

.mask-panel .item {
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  height: 5.6rem;
  padding: 0 2rem;
  display: flex;
}

.mask-panel .item .faqs {
  color: #fff;
  border-bottom: 1px solid #eee;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5.6rem;
  padding: 0;
  font-size: 1.6rem;
  display: flex;
}

.mask-panel .item-btn {
  box-sizing: border-box;
  padding: 24px 16px 40px;
}

.mask-panel .button {
  width: 100%;
  margin-left: 0;
}

@media (width >= 769px) and (width <= 1200px) {
  .box-width {
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 auto;
  }

  .top-con, .milestone-box, .content-con, .investors {
    box-sizing: border-box;
    padding: 0 24px;
  }

  .btn-con .img-1 > img {
    width: 196px;
    height: 65.6px;
  }

  .btn-con .img-2 > img, .btn-con .img-3 > img {
    height: 65.6px;
  }

  .investors {
    padding-bottom: 40px;
  }

  .header-width {
    width: 90%;
  }
}

@media screen and (width >= 768px) {
  .isHidePc {
    display: none !important;
  }
}

@media screen and (width <= 769px) {
  .body-content {
    inset: 0;
    overflow: hidden auto;
  }

  .box-width {
    width: auto;
  }

  #close {
    z-index: 10;
    background-color: #121212;
    inset: 0;
    overflow: hidden auto;
  }

  #close .left-text {
    color: #fff;
  }

  .isHidePc .logo {
    margin-left: -1.5rem;
  }

  .middle-title > div {
    border-radius: 10px;
    padding: 1rem 2rem;
  }

  .middle-title svg {
    width: 20px;
    height: 30px;
  }

  .middle-title span {
    margin-left: -1rem;
    font-size: 1.8rem;
  }

  .isHideMobile {
    display: none !important;
  }

  .header {
    padding: 0 2rem;
  }

  .top-con {
    box-sizing: border-box;
    background-size: auto 40rem;
    width: 100%;
    padding: 2rem;
  }

  .top-con > div {
    padding: 0;
    display: block;
  }

  .top-con .top-left {
    text-align: center;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .top-con .title {
    padding-top: 10px;
    font-size: 4.6rem;
  }

  .top-con .title2 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }

  .top-con .top-left-title {
    margin: 0;
    font-size: 1.8rem;
  }

  .top-con .top-left-title:after {
    display: none;
  }

  .top-con .top-left-text {
    margin: 0 0 10px;
    font-size: 1.6rem;
  }

  .top-con .top-right {
    box-sizing: border-box;
    background: url("rectangle.6cf13224.png") -20rem -2rem / auto 100% no-repeat;
    width: 100vw;
    margin-left: -2rem;
    padding: 2rem 2rem 10rem;
  }

  .top-con .top-right .user-box {
    width: 11rem;
    height: 4rem;
    top: 2rem;
    right: 2rem;
  }

  .top-con .top-right .user-box svg {
    width: 3rem;
    height: 3rem;
    position: relative;
    top: .7rem;
  }

  .top-con .top-right .user-box svg rect {
    fill: #3671ee;
  }

  .top-con .top-right .user-box p {
    flex: none;
    margin-left: 0;
  }

  .top-con .top-right .app-style {
    border-radius: 12px;
    width: 48%;
    height: 32rem;
    margin-top: 5rem;
  }

  .top-con .top-right .app-style img {
    height: 32rem;
  }

  .top-con .top-right .app-box {
    border-radius: 12px;
    width: 48%;
    height: 32rem;
    margin-top: 5rem;
  }

  .top-con .top-right .app-box img {
    height: 32rem;
  }

  .top-con .top-right .app-box2 {
    border-radius: 12px;
    width: 48%;
    height: 32rem;
    margin-top: 5rem;
    margin-left: 4%;
  }

  .top-con .top-right .app-box2 img {
    height: 32rem;
  }

  .top-con .btn-con {
    position: inherit;
    flex-wrap: wrap;
    align-items: center;
    height: auto;
    margin: 3rem 0;
    padding: 2rem 2rem 1rem;
    display: flex;
    bottom: 0;
  }

  .top-con .btn-con .button {
    width: 48.5%;
    padding-bottom: .5rem;
  }

  .top-con .btn-con .button > img {
    width: 100%;
    height: 6rem;
  }

  .top-con .btn-con .img-1 > img {
    width: 100%;
  }

  .top-con .btn-con .img-2 {
    width: 42%;
  }

  .top-con .btn-con .img-3 {
    width: 55%;
  }

  .milestone-box {
    padding: 3rem 2rem 7rem;
  }

  .milestone-box .box-width {
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    display: flex;
  }

  .milestone-box .box-width:after {
    display: none;
  }

  .milestone-box .mil-left {
    width: 100%;
    padding-top: 2rem;
  }

  .milestone-box .mil-left .middle-title > div {
    width: auto;
    padding: 1rem 2rem;
  }

  .milestone-box .mil-left .mil-list {
    align-items: flex-start;
    width: 100%;
    height: 50rem;
    padding: 5rem 0 2.5rem;
  }

  .milestone-box .mil-left .mil-list > div > div {
    padding-top: 0;
    line-height: 83px;
  }

  .milestone-box .mil-left .mil-list > div > p {
    font-size: 16px;
  }

  .milestone-box .mil-right {
    width: 100%;
  }

  .milestone-box .mil-right img {
    width: 110vw;
    left: 9vw;
  }

  .content-con {
    background: #fff;
    padding: .3rem 2rem 0;
  }

  .content-con .box-width {
    padding-bottom: 2rem;
  }

  .content-con .box-width:after, .content-con .box-width:before {
    display: none;
  }

  .middle-con {
    background: #fff;
    justify-content: center;
    margin-top: 4rem;
  }

  .middle-con .item {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 4rem;
    padding: 0 4rem;
  }

  .middle-con .item .label {
    width: 100%;
  }

  .investors {
    margin-top: 0;
    margin-bottom: 0;
    padding: 6rem 0;
  }

  .investors .box-width:after {
    display: none;
  }

  .investors .middle-title svg {
    width: 28px;
    height: 38px;
  }

  .investors .investors-img-con {
    justify-content: center;
    margin-top: 2rem;
  }

  .investors .investors-img-con .investors-img-item {
    border-radius: .8rem;
    width: 42%;
    margin: 1rem .6rem;
  }

  .bottom-con {
    padding: 4rem 2rem;
  }

  .bottom-con .bottom-width {
    width: auto;
  }

  .bottom-con .bottom-box {
    flex-wrap: wrap-reverse;
  }

  .bottom-con .bottom-box > div {
    width: 100%;
  }

  .bottom-con .bottom-box .foot-text {
    font-weight: normal;
  }

  .bottom-con .bottom-box .foot-text a {
    color: #3671ee;
    text-decoration: none;
  }

  .bottom-con .link-con .link-item {
    background-color: #0000;
    margin-right: 2rem;
  }

  .bottom-con .link-con .link-item svg {
    width: 2.4rem;
    height: 2.4rem;
  }

  .bottom-con .link-con .link-item svg path {
    fill: #333;
  }

  .service-con {
    padding: 2rem 0;
  }

  .service-con .lang-box {
    display: none !important;
  }
}

/*# sourceMappingURL=index.d2537f60.css.map */
