@font-face {
    font-family: 'Kanit-Light';
    src: url('../../common/font/Kanit-Light.ttf');
}

@font-face {
    font-family: 'Kanit-SemiBold';
    src: url('../../common/font/Kanit-SemiBold.ttf');
}

html {
    font-size: 62.5%;
    font-family: 'Kanit-Light', 'Kanit-SemiBold';
}

body {
    margin: 0;
    background: #ffffff;
    font-weight: 400;
    font-size: 1.4rem;
}

#pc-head {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 1px solid #999;
        transform: scaleY(0.5);
    }
}

.box-width {
    margin: 0 auto;
    max-width: 1200px;
}

.header-width {
    .box-width;
    width: 1200px;
    // border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
        margin-left: -15px;
    }
}

.header {
    height: 8rem;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 0 10rem;
    box-sizing: border-box;
    font-family: Kanit-SemiBold;
    font-size: 1.4rem;

    .left {
        flex: 1;
        display: flex;
        align-items: center;

        .logo {
            position: relative;
            bottom: -0.3rem;
            height: 7.5rem;
            // border:1px solid red;
        }

        .left-text {
            margin-left: -12px;
            color: #3671ee;
            font-size: 18px;

            > div {
                line-height: 20px;
            }
        }
    }

    .right {
        display: flex;
        align-items: center;
    }
    .down-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: 20px;
        &:hover {
            path {
                fill: #3671ee !important;
            }
        }
    }
    .connect-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 60px;
        background: #3671ee;
        width: 158px;
        box-sizing: border-box;
        height: 40px;
        cursor: pointer;
        .btn-label {
            margin-left: 6px;
            font-family: Arial;
            font-size: 16px;
            color: #fff;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }
    }
    .has-connect-btn {
        display: inline-flex;
        height: 40px;
        width: 158px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        gap: 6px;
        flex-shrink: 0;
        border-radius: 60px;
        border: 1px solid #000;
        position: relative;
        cursor: pointer;
        .logout-con {
            display: none;
        }
        &:hover {
            .logout-con {
                position: absolute;
                top: 40px;
                left: 0;
                border-top: 4px solid transparent;
                display: block;
                z-index: 1;
                .logout {
                    display: flex;
                    cursor: pointer;
                    height: 40px;
                    width: 158px;
                    padding: 0px 16px;
                    box-sizing: border-box;
                    align-items: center;
                    align-self: stretch;
                    // color: #fff;
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; /* 150% */
                    background: #fff;
                    border-radius: 60px;
                    text-align: right;
                    border: 1px solid #000;
                }
            }
            svg {
                transform: scaleY(-1);
            }
        }
        .address {
            color: #000;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
        }
        svg {
            width: 12px;
            height: 12px;
        }
    }

    .faqs {
        color: #121212;
        text-align: center;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 1.5rem;
        text-decoration: none;
        padding: 1.5rem 2rem 1.5rem 2rem;

        &:hover {
            color: #3671ee;
        }
    }

    .new-con {
        display: flex;
        align-items: center;

        // padding-right: 0;
        svg {
            margin-left: 0.5rem;
        }
    }

    .button {
        box-sizing: border-box;
        background: #3775f6;
        border-radius: 2rem;
        border: 2px solid #3775f6;
        color: #fff;
        display: inline-block;
        font-family: Open Sans;
        height: 4rem;
        line-height: 3.6rem;
        width: 12rem;
        font-size: 1.5rem;
        margin-left: 2rem;
        color: #fff;
        text-align: center;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;

        &:hover {
            border-radius: 2rem;
            border: 2px solid #000;
            background: #fff;
            color: #000;
        }
    }
}

.top-con {
    width: 100%;
    background: #fff;
    background-image: url('../image/rectangle.png');
    background-size: auto 850px;
    background-repeat: no-repeat;
    background-position: top right;
    // border: 1px solid red;

    > div {
        padding: 8rem 0 50rem;
        position: relative;
        display: flex;
        justify-content: space-between;
        // flex-wrap: wrap;
        align-items: center;
        // border: 1px solid red;
    }

    .top-left {
        flex: 1;
        // border: 1px solid red;
    }

    .title {
        padding-top: 30px;
        color: #121212;
        font-family: Open Sans;
        font-size: 75px;
        font-style: normal;
        font-weight: 700;
    }

    .title2 {
        color: #3671ee;
        font-family: Open Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 30px;
    }

    .padding-left {
        margin-left: 30px;
    }

    .top-left-title {
        .padding-left;
        position: relative;
        color: #333;
        font-family: Open Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;

        &::after {
            content: '';
            position: absolute;
            top: 11px;
            left: -20px;
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50px;
            background-color: #333;
        }

        .tag {
            background-color: #3671ee;
            font-size: 14px;
            line-height: 16px;
            height: 18px;
            font-weight: normal;
            border-radius: 2px;
            padding: 0px 6px;
            position: relative;
            color: #fff;
            right: 0px;
            top: 0;
            display: inline-block;
            margin-left: 1rem;
        }
        .tag::before {
            content: ' ';
            width: 0;
            height: 0;
            left: -4px;
            top: 4px;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-right: 5px solid #3671ee;
            position: absolute;
        }
    }

    .top-left-text {
        .padding-left;
        color: var(--light-t-1-day, #333);
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
    }

    .top-left-btn {
        margin-top: 40px;
        display: flex;
        width: 230px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        background: var(--dark-tanglepay, #3671ee);
        box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.12);
        color: var(--dark-t-1, #fff);
        font-family: Open Sans;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;
        box-sizing: border-box;
        border: 2px solid var(--light-t-1-day, #3671ee);

        &:hover {
            color: #333;
            border: 2px solid var(--light-t-1-day, #333);
            background: var(--dark-t-1, #fff);
            box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.12);

            svg {
                path {
                    fill: #000;
                }
            }
        }

        svg {
            margin-left: 13px;
        }
    }
}

.btn-con {
    box-sizing: border-box;
    width: 100%;
    height: 210px;
    padding: 0 55px;
    border-radius: 16px;
    background: #fafaff;
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    bottom: 11rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // flex-wrap: wrap;
    .show-img {
        display: none;
    }

    .button {
        width: auto;

        // border: 1px solid red;

        > img {
            height: 82px;
        }

        &:hover {
            > img {
                display: none;
            }

            .show-img {
                display: block;
            }
        }
    }

    .img-1 {
        > img {
            width: 245px;
        }
    }
}

.top-right {
    padding-left: 20px;
    width: 61%;
    position: relative;
    display: flex;
    justify-content: flex-end;

    .app-border {
        > img {
            overflow: hidden;
            border-radius: 16px;
            box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.12);
            width: 100%;
        }
    }

    .app-box {
        .app-border;
        width: 45%;
        height: 617px;
    }

    .app-box2 {
        margin-top: 100px;
        margin-left: 35px;
        .app-border;
        width: 40%;
        border-radius: 12px;
        height: 467px;
    }

    .user-box {
        box-sizing: border-box;
        position: absolute;
        top: 40px;
        right: 0;
        width: 140px;
        height: 50px;
        border-radius: 16px;
        background: var(--white-100, #fff);
        box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.12);
        display: flex;
        align-items: center;
        padding: 0 5px;

        div {
            width: 40px;
            height: 40px;
        }

        p {
            margin-left: 10px;
            flex: 1;
            overflow: hidden;
        }
    }
}

.milestone-box {
    padding: 80px 0;
    background: #f2f2f6;

    .box-width {
        position: relative;
        display: flex;
        justify-content: space-between;

        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 210px;
            right: 210px;
            bottom: 0;
            border-radius: 36px;
            border: 2px dashed rgba(0, 0, 0, 0.16);
        }
    }

    .mil-left {
        // border: 1px solid red;
        position: relative;
        width: 420px;
        padding-top: 40px;
        z-index: 2;

        .middle-title {
            > div {
                width: 100%;
                padding: 1.5rem 0;
            }
        }

        .mil-list {
            margin: 40px auto 0;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 420px;
            height: 540px;
            box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.12);
            border-radius: 36px;
            padding: 3.4rem 0;
            background-color: #fff;

            > div {
                width: 65%;
                height: 110px;
                text-align: center;
                border-radius: 16px;
                background: #3671ee;
                box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);

                > div {
                    box-sizing: border-box;
                    padding-top: 20px;
                    height: 73px;
                    color: #fff;
                    font-family: Open Sans;
                    font-size: 34px;
                    font-style: normal;
                    font-weight: 700;
                    // border: 1px solid red;
                }

                > p {
                    margin-top: 0;
                    color: #fff;
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-family: Open Sans;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 600;
                    // border: 1px solid red;
                }
            }
        }
    }

    .mil-right {
        position: relative;
        z-index: 2;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 400px;
        // border: 1px solid red;

        img {
            position: relative;
            right: -83px;
            // border: 1px solid red;
            width: 750px;
        }
    }
}

//----
.content-con {
    padding: 5rem 0;
    background: #fff;

    .box-width {
        position: relative;
        padding: 6rem 0;

        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 9rem;
            left: 180px;
            right: 180px;
            bottom: 0;
            border-radius: 36px;
            border: 2px dashed rgba(0, 0, 0, 0.16);
        }

        &::before {
            display: block;
            content: '';
            position: absolute;
            top: 9rem;
            left: 50%;
            bottom: 0;
            border-left: 2px dashed rgba(0, 0, 0, 0.16);
        }

        // border: 1px solid red;
    }
}

.middle-title {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    // border: 1px solid red;

    > div {
        display: flex;
        padding: 1.5rem 5rem;
        justify-content: center;
        align-items: center;
        gap: 24px;
        flex-shrink: 0;
        border-radius: 20px;
        border: 1px solid rgba(0, 0, 0, 0.13);
        background: var(--dark-t-1, #fff);
        box-shadow: 4px 4px 36px 0px rgba(0, 0, 0, 0.12);
    }

    svg {
        width: 22px;
        height: 32px;
    }

    span {
        margin-left: -7px;
        color: #000;
        text-align: center;
        font-family: Open Sans;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
    }
}

.middle-con {
    position: relative;
    z-index: 2;
    margin-top: 5.5rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    text-align: center;

    .item {
        box-sizing: border-box;
        width: 30.2%;
        height: 48rem;
        padding: 0 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 32px;
        border: 1px solid rgba(0, 0, 0, 0.16);
        background: var(--dark-t-1, #fff);
        box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.12);

        img {
            // width: 11rem;
            height: 15rem;
        }

        .title {
            text-align: center;
            font-size: 2rem;
            font-weight: 700;
            margin: 2rem 0;
            line-height: 3rem;
        }

        .label {
            font-size: 1.6rem;
            text-align: center;
        }
    }
}

.investors {
    width: 100%;
    padding: 10rem 0;
    background: #f2f2f6;

    .box-width {
        position: relative;

        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 3.2rem;
            left: 0;
            right: 0;
            bottom: -20px;
            border-radius: 36px;
            border: 2px dashed rgba(0, 0, 0, 0.16);
        }
    }

    .middle-title {
        svg {
            width: 29px;
            height: 39px;
        }
    }

    .investors-img-con {
        margin-top: 5rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;

        .investors-img-item {
            width: 20.5%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            margin: 0 2rem 3rem 2rem;
            border-radius: 20px;
            border: 1px solid rgba(0, 0, 0, 0.16);
            box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.12);

            img {
                width: 80%;
            }
        }
    }
}

.bottom-con {
    box-sizing: border-box;
    position: relative;
    background: #fff;
    width: 100%;
    padding: 8rem 0;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid #999;
        transform: scaleY(0.5);
    }

    .bottom-width {
        width: 890px;
        margin: 0 auto;
        // border: 1px solid red;
    }

    .logo-con {
        display: flex;
        align-items: center;
        // border: 1px solid red;

        .logo {
            margin-left: -15px;
            position: relative;
            bottom: 0;
            height: 7rem;
        }

        .left-text {
            position: relative;
            left: -10px;
            top: -2px;
            color: #333;
            font-size: 18px;
            font-weight: bold;

            > div {
                font-family: Kanit-SemiBold;
                text-align: left;
                line-height: 20px;
            }
        }
    }

    .bottom-box {
        display: flex;
    }

    .foot-bottom-text {
        flex: 1;
    }

    .foot-text {
        color: #333;
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .link-con {
        display: flex;
        align-items: center;
        padding: 2rem 0;
        width: 100%;

        .link-item {
            margin-right: 1.3rem;
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            background-color: #000;
            border-radius: 30px;

            svg {
                width: 1.6rem;
                height: 1.6rem;
            }
        }

        .foot-info {
            color: var(--light-t-1-day, #333);
            font-family: Open Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
    }

    .service-con {
        position: relative;
        width: 31%;

        a {
            display: block;
            margin-bottom: 1rem;
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            text-decoration: none;
            color: #888d95;
        }

        .lang-box {
            position: absolute;
            top: 0;
            right: 50px;
            font-family: Open Sans;
            font-size: 12px;
            color: #888d95;
            display: flex;
            align-items: center;

            svg {
                margin-right: 7px;
                width: 1.6rem;
                height: 1.6rem;
            }
        }
    }
}

.button {
    cursor: pointer;
}

.button:active {
    opacity: 0.7;
}

/* 设置滚动条的样式 */
*::-webkit-scrollbar {
    width: 6px;
}

*::-webkit-scrollbar-track {
    border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
}

.hidden {
    display: none !important;
}

.mask-con {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 8rem;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.mask-panel {
    height: 100vh;
    background-color: #121212;
    // border-top: 1px solid #eee;

    .item {
        box-sizing: border-box;
        padding: 0 2rem;
        width: 100%;
        height: 5.6rem;
        display: flex;
        align-items: center;

        .faqs {
            height: 5.6rem;
            padding: 0;
            width: 100%;
            font-size: 1.6rem;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #eee;
        }
    }

    .item-btn {
        padding: 24px 16px 40px 16px;
        box-sizing: border-box;
    }

    .button {
        width: 100%;
        margin-left: 0;
    }
}
@media (min-width: 769px) and (max-width: 1200px) {
    .box-width {
        margin: 0 auto;
        max-width: 100%;
        box-sizing: border-box;
    }
    .top-con,
    .milestone-box,
    .content-con,
    .investors {
        padding: 0 24px;
        box-sizing: border-box;
    }
    .btn-con .img-1 > img {
        width: 196px;
        height: 65.6px;
    }
    .btn-con .img-2 > img {
        height: 65.6px;
    }
    .btn-con .img-3 > img {
        height: 65.6px;
    }
    .investors {
        padding-bottom: 40px;
    }
    .header-width {
        width: 90%;
    }
}

@media screen and (min-width: 768px) {
    .isHidePc {
        display: none !important;
    }
}

// 移动端
@media screen and (max-width: 769px) {
    .body-content {
        // position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .box-width {
        width: auto;
    }

    #close {
        .body-content;
        background-color: #121212;

        .left-text {
            color: #fff;
        }

        z-index: 10;
    }

    .isHidePc {
        .logo {
            margin-left: -1.5rem;
        }
    }

    .middle-title {
        > div {
            padding: 1rem 2rem;
            border-radius: 10px;
        }

        svg {
            width: 20px;
            height: 30px;
        }

        span {
            margin-left: -1rem;
            font-size: 1.8rem;
        }
    }

    .isHideMobile {
        display: none !important;
    }

    .header {
        padding: 0 2rem;
    }

    .top-con {
        box-sizing: border-box;
        background-size: auto 40rem;
        width: 100%;
        padding: 2rem;

        > div {
            padding: 0;
            display: block;
        }

        .top-left {
            padding-left: 0;
            display: flex;
            text-align: center;
            flex-direction: column;
            align-items: center;
        }

        .title {
            font-size: 4.6rem;
            padding-top: 10px;
        }

        .title2 {
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }

        .top-left-title {
            margin: 0;
            font-size: 1.8rem;

            &::after {
                display: none;
            }
        }

        .top-left-text {
            font-size: 1.6rem;
            margin: 0 0 10px 0;
        }

        .top-right {
            width: 100vw;
            margin-left: -2rem;
            background: url('../image/rectangle.png') no-repeat;
            background-size: auto 100%;
            background-position-x: -20rem;
            background-position-y: -2rem;
            box-sizing: border-box;
            padding: 2rem 2rem 10rem;
            // border: 1px solid red;

            .user-box {
                top: 2rem;
                right: 2rem;
                width: 11rem;
                height: 4rem;

                svg {
                    position: relative;
                    top: 0.7rem;
                    width: 3rem;
                    height: 3rem;
                    // border: 1px solid red;

                    rect {
                        fill: #3671ee;
                    }
                }

                p {
                    margin-left: 0;
                    flex: none;
                    // border: 1px solid red;
                }
            }

            .app-style {
                margin-top: 5rem;
                width: 48%;
                height: 32rem;
                border-radius: 12px;

                // border:1px solid red;
                img {
                    height: 32rem;
                }
            }

            .app-box {
                .app-style;
            }

            .app-box2 {
                margin-left: 4%;
                .app-style;
            }
        }

        .btn-con {
            position: inherit;
            display: flex;
            padding: 0;
            bottom: 0;
            height: auto;
            align-items: center;
            flex-wrap: wrap;
            padding: 2rem 2rem 1rem;
            margin: 3rem 0;
            flex-wrap: wrap;
            // border: 1px solid red;

            .button {
                padding-bottom: 0.5rem;
                width: 48.5%;

                > img {
                    width: 100%;
                    height: 6rem;
                }
            }

            .img-1 {
                > img {
                    width: 100%;
                }
            }

            .img-2 {
                width: 42%;
            }

            .img-3 {
                width: 55%;
            }
        }
    }

    .milestone-box {
        padding: 3rem 2rem 7rem;

        .box-width {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap-reverse;

            &::after {
                display: none;
            }
        }

        .mil-left {
            padding-top: 2rem;
            width: 100%;

            .middle-title {
                > div {
                    width: auto;
                    padding: 1rem 2rem;
                }
            }

            .mil-list {
                align-items: flex-start;
                padding: 5rem 0 2.5rem;
                width: 100%;
                height: 50rem;

                > div {
                    > div {
                        padding-top: 0;
                        line-height: 83px;
                    }

                    > p {
                        font-size: 16px;
                    }
                }
            }
        }

        .mil-right {
            width: 100%;
            // border: 1px solid red;

            img {
                left: 9vw;
                // border: 1px solid red;
                width: 110vw;
            }
        }
    }

    .content-con {
        padding: 0.3rem 2rem 0;
        background: #fff;

        .box-width {
            padding-bottom: 2rem;

            &::after {
                display: none;
            }

            &::before {
                display: none;
            }
        }
    }

    .middle-con {
        margin-top: 4rem;
        background: #fff;
        justify-content: center;

        .item {
            width: 100%;
            padding: 0 4rem;
            box-sizing: border-box;

            .label {
                width: 100%;
            }

            margin-bottom: 4rem;
        }
    }

    .investors {
        margin-top: 0rem;
        padding: 6rem 0;
        margin-bottom: 0;

        .box-width {
            &::after {
                display: none;
            }
        }

        .middle-title {
            svg {
                width: 28px;
                height: 38px;
            }
        }

        .investors-img-con {
            margin-top: 2rem;
            justify-content: center;

            .investors-img-item {
                margin: 1rem 0.6rem;
                width: 42%;
                border-radius: 0.8rem;
            }
        }
    }

    .bottom-con {
        padding: 4rem 2rem;

        .bottom-width {
            width: auto;
        }

        .bottom-box {
            flex-wrap: wrap-reverse;

            > div {
                width: 100%;
            }

            .foot-text {
                font-weight: normal;

                a {
                    color: #3671ee;
                    text-decoration: none;
                }
            }
        }

        .link-con {
            .link-item {
                margin-right: 2rem;
                background-color: transparent;

                svg {
                    width: 2.4rem;
                    height: 2.4rem;

                    path {
                        fill: #333;
                    }
                }
            }
        }
    }

    .service-con {
        padding: 2rem 0;

        .lang-box {
            display: none !important;
        }
    }
}
